import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  lastName: '',
  address: '',
  profileImage: '',
  restaurant: '',
  jwt: null,
  employee_id: '',
  employeer_id: '',
};

const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    setPersonData: ( state, action ) => {
      state.name         = action.payload.name;
      state.lastName     = action.payload.lastName;
      state.address      = action.payload.address;
      state.restaurant   = action.payload.restaurant;
      state.profileImage = action.payload.profileImage;
      state.employee_id  = action.payload.employee_id;
      state.employeer_id = action.payload.employeer_id;
    },
    setToken: ( state, action ) => {
      state.jwt = action.payload;
    },
  },
});

export const { setPersonData, setToken } = personSlice.actions;
export default personSlice.reducer;
