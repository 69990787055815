import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import close from '../assets/images/close.png';
import { setInputValue } from '../features/input/inputSlice';

const Input = () => {

    const inputRef = useRef(null);
    const symbolRef = useRef(null);

    const getTextWidth = (text, font) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    };

    const updateSymbolPosition = () => {
        const input = inputRef.current;
        const symbol = symbolRef.current;

        if (!input || !symbol) {
            return; // Return early if refs aren't set
        }

        // Get the width of the text inside the input
        const textWidth = getTextWidth(input.value, window.getComputedStyle(input).font);

        // Position the symbol right next to the text
        symbol.style.left = (textWidth + 15) + 'px';
    };

    useEffect(() => {
        updateSymbolPosition();
    }, []);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        if (newValue === "" || !isNaN(newValue)) {
            dispatch(setInputValue(newValue));
        }
        updateSymbolPosition();
    };

    const handleResetClick = () => {
        dispatch(setInputValue(''));
    };

    return (
        <>
            <div className="container ">
                <div className="title-section pt-10">
                    <label className="default-title p-0">{ t( 'tip' ) }</label>
                </div>
                <div className="tip-field pt-10" style={{ position: 'relative' }}>
                    <input
                        ref={inputRef}
                        type="number"
                        onChange={handleInputChange}
                        className="tip-value"
                        pattern="[0-9]*"
                    />
                    <div ref={symbolRef} className="currancySymbol"style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }}>
                        ₾
                    </div>
                    <button className="reset-btn" onClick={handleResetClick}>
                        <img src={close} alt="Reset tip" className="reset-btn-image" />
                    </button>
                </div>
            </div>
        </>
    );
};

export default Input;
