import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationGE from './locales/ge/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  ge: {
    translation: translationGE
  }
};

const savedLanguage = localStorage.getItem('i18nextLng') || 'ge'; // Get saved language or default to 'ge'

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: savedLanguage, // Set initial language to savedLanguage
    keySeparator: false,
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;
