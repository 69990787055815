// src/features/review/reviewSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedOption: '',
  comment: '',
};

const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    setSelectedOption: ( state, action ) => {
      state.selectedOption = action.payload;
    },
    setComment: ( state, action ) => {
      state.comment = action.payload;
    },
  },
});

export const { setSelectedOption, setComment } = reviewSlice.actions;
export default reviewSlice.reducer;
