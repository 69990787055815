import React, { Component } from 'react';
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import './i18nConfig';
import './App.css';
import Header from './components/Header';
import Person from './components/Person';
import Input from './components/Input'; 
import Rating from './components/Rating';
import Review from './components/Review';
import Commission from './components/Commission';
import Buttons from './components/Buttons';
import Terms from './components/Terms';
import { Provider } from "react-redux";
import store from "./store";

import PushNotificationComponent from './components/PushNotificationComponent';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      checked: false,
    };
  }

  handleInputChange = (newValue) => {
    this.setState({ inputValue: newValue });
  };

  handleInputReset = () => {
    this.setState({ inputValue: "" });
  };

  handleCheckboxChange = (checked) => {
    this.setState({ checked: checked });
  };

  render() {
    let sum = parseInt(this.state.inputValue) || 0;
    if (this.state.checked) {
      sum += sum * 0.1;
    }

    return (
       <Provider store={store}>
        <Router>
          <div className="wrapper">
            <Header />
            <Routes>
              <Route path="/" element={
                <React.Fragment>
                  <Person />
                  <Input value={this.state.inputValue} onInputChange={this.handleInputChange} />
                  <Rating />
                  <Review />
                  <Buttons sum={sum} />
                  <Commission checked={this.state.checked} onCheckboxChange={this.handleCheckboxChange} />
                  <Terms checked={this.state.checked} onCheckboxChange={this.handleCheckboxChange} />
                </React.Fragment>
              } />
            </Routes>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
