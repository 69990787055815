// src/rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';

import reviewReducer from './features/review/reviewSlice';
import inputReducer from './features/input/inputSlice';
import ratingReducer from './features/rating/ratingSlice';
import personReducer from './features/person/personSlice';
import ammountReducer from './features/ammount/ammountSlice';
import buttonReducer from './features/button/buttonSlice';
import commissionReducer from './features/commission/commissionSlice';
import headerReducer from './features/header/headerSlice';
import termsReducer from './features/terms/termsSlice';

export default combineReducers({
  review: reviewReducer,
  input: inputReducer,
  rating: ratingReducer,
  person: personReducer,
  ammount: ammountReducer,
  button: buttonReducer,
  commission:commissionReducer,
  header: headerReducer,
  terms: termsReducer
});
