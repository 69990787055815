import React from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { setRating } from '../features/rating/ratingSlice';

class Rating extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      rating: 0,
    };
  }

  handleStarClick = ( rating ) => {
    this.setState( { rating } );
    this.props.dispatch( setRating( rating ) );
  };

  render() {
    const { rating } = this.state;
    const { t } = this.props;

    return (
      <>
        <div className="container mt-10">
          <div className="title-section pb-10">
            <label className="default-title p-0">{ t( 'review_title' ) }</label>
          </div>
          <div className="rating">
            { [1, 2, 3, 4, 5].map( ( i ) => (
              <FontAwesomeIcon
                key={ i }
                icon={ faStar }
                className={ `star ${ i <= rating ? 'filled' : '' }` }
                onClick={ () => this.handleStarClick( i ) }
              />
            ) ) }
          </div>
        </div>
      </>
    );
  }
}

// Corrected order of function calls
export default withTranslation()( connect()( Rating ) );
