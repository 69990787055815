import React from "react";
import { withTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp, faGrinStars } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { setSelectedOption, setComment } from '../features/review/reviewSlice';

class Review extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: '',
            showTextArea: false, 
            textAreaValue: '',           
        };
    }

    handleOptionChange = (event) => {
        this.setState({
            selectedOption: event.target.value,
            showTextArea: true,
        });
        this.props.setSelectedOption(event.target.value);
    }

    handleTextAreaChange = (event) => {
        this.setState({
            textAreaValue: event.target.value
        });
        this.props.setComment(event.target.value);
    }

    render() {
        const { selectedOption, showTextArea } = this.state;
        const { t } = this.props;
        return (
            <>  <div className="container mt-10">
                <div className="title-section  pb-10">
                    <label className="default-title p-0">{ t('rate_place') }</label>
                </div>
                <div className="btn-group review-section" role="group">
                    <input type="radio" id="bad" name="review" value="bad" 
                        checked={selectedOption === 'bad'} 
                        onChange={this.handleOptionChange} />
                    <label className="btn btn-outline-primary review-btn" htmlFor="bad">
                        <FontAwesomeIcon icon={faThumbsDown} /><br/>{ t('bad') }
                    </label>

                    <input type="radio" id="good" name="review" value="good" 
                        checked={selectedOption === 'good'} 
                        onChange={this.handleOptionChange} />
                    <label className="btn btn-outline-primary review-btn" htmlFor="good">
                        <FontAwesomeIcon icon={faThumbsUp} /><br/>{ t('good') }
                    </label>

                    <input type="radio" id="great" name="review" value="great" 
                        checked={selectedOption === 'great'} 
                        onChange={this.handleOptionChange} />
                    <label className="btn btn-outline-primary review-btn" htmlFor="great">
                        <FontAwesomeIcon icon={faGrinStars} /><br/>
                        <span>{ t('great') }</span>
                    </label>
                </div>

                <CSSTransition
                    in={showTextArea}
                    timeout={300}
                    classNames="slide"
                    unmountOnExit
                >
                    <div>
                        {showTextArea && (
                            <>
                                <div className="title-section pt-10">
                                    <label className="default-title p-0">{t('comment')}</label>
                                </div>
                                <textarea 
                                    className="form-control mt-3 review-text" 
                                    rows="3"
                                    onChange={this.handleTextAreaChange} 
                                />
                            </>
                        )}
                    </div>
                </CSSTransition>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      setSelectedOption: (option) => dispatch(setSelectedOption(option)),
      setComment: (comment) => dispatch(setComment(comment))
    };
};

export default connect(null, mapDispatchToProps)(withTranslation()(Review));
