import React from "react";
import { redirect, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { setPersonData, setToken } from "../features/person/personSlice";
import Cookies from 'js-cookie';
import axios from 'axios';
import personImage from '../assets/images/person.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


// This function gets the search parameters from the current location
function useQuery() {
    return new URLSearchParams( useLocation().search );
}
// This is a HOC that uses the hooks and passes the params as props
function withQueryParams( WrappedComponent ) {
    return function( props ) {
        const navigate = useNavigate();
        const query = useQuery();
        const id = query.get( "userID" );
        
        useEffect(() => {
            if (id === null || typeof id !== 'string' || !id.match(/^\d+$/)) {
                window.location.href = "https://www.etippy.ge"; 
                return; 
            }
        }, [id]);  // Dependency array for useEffect

        return <WrappedComponent { ...props } userID={ id } />;
    };
}

class Person extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            jwt: null, // Add jwt to state
        };
        this.authenticateAndFetchUserData = this.authenticateAndFetchUserData.bind( this );
    }

    authenticate = async () => {
        try {
            const response = await axios.post( 'https://admin.etippy.ge/api/login', {
                email: 'admin@gmail.com',
                password: '123456'
            });

            this.setState( { jwt: response.data.data.token } );
            Cookies.set( 'jwt', response.data.data.token );
            return response.data.data.token;
        } catch ( error ) {
            console.error('Authentication failed:', error);
        }
    };

    componentDidMount() {
        this.authenticateAndFetchUserData();
        this.props.i18n.on( 'languageChanged', this.authenticateAndFetchUserData );
    }

    componentWillUnmount() {
        this.props.i18n.off( 'languageChanged', this.authenticateAndFetchUserData );
    }

    authenticateAndFetchUserData = async () => {
        const jwt = await this.authenticate();
        if ( jwt ) {
            this.props.setToken( jwt );
            this.fetchUserData( jwt );
        }
    }

    fetchUserData = async ( jwt ) => {
        const { userID } = this.props;
        const lang = this.props.i18n.language;
        
        var imgName = '';
        try {
            const response = await axios.get(`https://admin.etippy.ge/api/employees/${ userID }`, {
                headers: {
                    'Authorization': `Bearer ${ jwt }`,
                    'Accept-Language': this.props.i18n.language,
                },
            });
            if(response.data.data.image == 'No Image'){
                if(response.data.data.sex == 'female'){
                    imgName = 'female.png';
                }else{
                    imgName = 'male.jpeg'
                }
            }else{
                imgName = response.data.data.image;
            }
            
            // console.log(response.data.data);
            const data = { 
                name: response.data.data.name, 
                lastName: response.data.data.lastName,
                address: response.data.data.address,
                profileImage: 'https://admin.etippy.ge/images/' + imgName,
                restaurant: response.data.data.user_name,
                employee_id: response.data.data.id,
                employeer_id: response.data.data.employeer,
            };
            this.props.setPersonData( data ); // Dispatch Redux action here
        } catch ( error ) {
            console.error( 'Failed to fetch user data:', error );
        }
    };

    render() {
        const { name, lastName, profileImage, restaurant, address } = this.props.person; // Use data from Redux store
        //console.log(this.props.person);
        return (
            <>  
                <div className="person-section">
                    <div className="left">
                        <h3 className='title-lg cl-white mt-25 bold'>{ restaurant }</h3>
                        <p className="title-md cl-white">{ address }</p>
                    </div>
                    <div className="right">
                        <img src={ profileImage } className="personImage"/> 
                        <h2 className="title-sm cl-white bold">{ name }</h2> 
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ( state ) => {
    return {
        person: state.person
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        setPersonData: ( person ) => dispatch( setPersonData( person ) ),
        setToken: ( token ) => dispatch( setToken( token ) )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( withTranslation()( withQueryParams( Person ) ) );
