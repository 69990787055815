import React from 'react';
import { withTranslation } from 'react-i18next';
import logo from '../assets/images/logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faTimes } from "@fortawesome/free-solid-svg-icons";
import Person from './Person';

class Header extends React.Component {
    constructor( props ) {
        super( props );
    }
    node = React.createRef();
    state = { dropdownOpen: false };

    languages = [
        {
            code: 'en',
            name: 'English'
        },
        {
            code: 'ge',
            name: 'ქართული'
        }
    ];

    componentDidMount() {
        document.addEventListener( "mousedown", this.handleClickOutside );
        const lang = localStorage.getItem( 'i18nextLng' );  // Get language from local storage
        if ( lang ) { 
            this.props.i18n.changeLanguage( lang ); 
        }
    }

    componentWillUnmount() {
        document.removeEventListener( "mousedown", this.handleClickOutside );
    }

    handleClickOutside = event => {
        if ( this.node.current.contains( event.target ) ) {
            return;
        }
        this.setState({ dropdownOpen: false });
    };

    toggleDropdown = () => {
        this.setState( prevState => ( { dropdownOpen: !prevState.dropdownOpen } ) );
    };

    changeLanguage = lang => {
        this.props.i18n.changeLanguage( lang );
        this.setState( { dropdownOpen: false } );
        localStorage.setItem( 'i18nextLng', lang );  // Save language to local storage
    };
    
    render() {
        const { dropdownOpen } = this.state;

        return (
            <header ref={ this.node }>
                <img src={ logo } className='main-logo' alt="Etippy"/>
                <div className="shape"></div>
                <div className="language-dropdown">
                    <div className='language-switcher'>
                        <button onClick={ this.toggleDropdown }>
                            <FontAwesomeIcon icon={ faGlobe } />
                        </button>
                    </div>
                    {dropdownOpen && (
                        <React.Fragment>
                            <div className="overlay" onClick={ this.toggleDropdown }></div>
                            <div className="language-menu">
                                
                                {this.languages.map(lang => (
                                    <div className='lang-switch-child' key={ lang.code } onClick={() => this.changeLanguage( lang.code )}>
                                        <div className='lang-n'>{ lang.name }</div>
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </header>
        );
    }
}

export default withTranslation()( Header );
