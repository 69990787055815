// src/features/commission/commissionSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checked: true, // Initialize the checked state to false
};

const commissionSlice = createSlice({
  name: "commission",
  initialState,
  reducers: {
    setCommissionChecked: ( state, action ) => {
      state.checked = action.payload;
    },
  },
});

export const { setCommissionChecked } = commissionSlice.actions;
export default commissionSlice.reducer;
