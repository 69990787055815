import React from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCommissionChecked } from '../features/commission/commissionSlice';

const Commission = ({ t }) => {
  const dispatch = useDispatch();
  const checked = useSelector( ( state ) => state.commission.checked );

  const handleCheckboxChange = ( event ) => {
    const isChecked = event.target.checked;
    dispatch( setCommissionChecked( isChecked ) );
  };

  return (
    <div className="custom-checkbox pt-10">
      <label className="switch">
        <input
          type="checkbox"
          id="checkbox"
          checked={ checked }
          onChange={ handleCheckboxChange }
        />
        <span className="slider round"></span>
      </label>
      <span className='note'>{ t( 'commission_agree' ) }</span>
    </div>
  );
};

export default withTranslation()( Commission );
