// src/features/commission/commissionSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checked: true, // Initialize the checked state to false
};

const termsSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {
    setTermsChecked: ( state, action ) => {
      state.checked = action.payload;
    },
  },
});

export const { setTermsChecked } = termsSlice.actions;
export default termsSlice.reducer;
