import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import gpay from '../assets/images/gpay.png';
import apple from '../assets/images/apple.png';
import { withTranslation } from 'react-i18next';

const Button = ({ t }) => {
  const dispatch   = useDispatch();
  const jwt        = useSelector(( state ) => state.person.jwt);
  const person     = useSelector(( state ) => state.person);
  const review     = useSelector(( state ) => state.review);
  const input      = useSelector(( state ) => state.input);
  const rating     = useSelector(( state ) => state.rating);
  const commission = useSelector(( state ) => state.commission);
  const terms      = useSelector(( state ) => state.terms);

  // New state for ammount
  const [ammount, setAmmount] = useState( 0 );

  // Effect to update sum whenever input or commission changes
  useEffect(() => {
    let newAmmount = parseInt( input.value ) || 0; 
    if ( commission.checked ) { newAmmount += newAmmount * 0.1; }
    setAmmount( newAmmount ); 
  }, [input.value, commission.checked]);

  const handleClick = async () => {
    if (!terms.checked) {
      alert('You must agree to the terms and conditions.'); 
      return;
    }
  
    if (ammount <= 0) {
      alert('Amount should be greater than zero.');
      return;
    }

    const data = {
      ...person,
      ...review,
      ...input,
      ...rating,
      ...commission,
      ...terms,
      ammount, // Add the calculated ammount to the data.
    };
        
    try {       
        // Make the API request here
        const response = await axios.post('https://admin.etippy.ge/api/transactions/create', data, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        });

        //Redirecting to TBC bank payment page
        window.location.href = response.data;

    } catch ( error ) {
        console.error('API request failed:', error);
        // Handle API request error here
    }
  };

  return (
    <div className="payment-btns">
      <h3 className="sum">
        { t( 'sum' ) }: { ammount } ₾
      </h3>
      <button className="gpay-btn">
        <img src={ gpay } alt={t( 'Google Pay' )} className="gpay" onClick={ handleClick }/>
      </button>
      <button className="apple-btn">
        <img src={ apple } alt={t( 'Apple Pay' )} className="appPay" onClick={ handleClick }/>
      </button>
      <button className="card-pay" onClick={ handleClick }>
        { t( 'cardPayment' ) }
      </button>
    </div>
  );
};

export default withTranslation()( Button );
