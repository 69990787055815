import React from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTermsChecked } from '../features/terms/termsSlice';

const Terms = ({ t }) => {
  const dispatch = useDispatch();
  const checked = useSelector( ( state ) => state.terms.checked );

  const handleCheckboxChange = ( event ) => {
    const isChecked = event.target.checked;
    dispatch( setTermsChecked( isChecked ) );
  };

  return (
    <div className="custom-checkbox pt-10">
      <label className="switch">
        <input
          type="checkbox"
          id="checkbox"
          checked={ checked }
          onChange={ handleCheckboxChange }
        />
        <span className="slider round"></span>
      </label>
      <span className='note'><a className='terms' href="https://www.etippy.ge/terms-and-conditions" target="_blank">{ t( 'terms' ) }</a></span>
    </div>
  );
};

export default withTranslation()( Terms );
