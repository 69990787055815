// features/header/headerSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lang: localStorage.getItem( 'i18nextLng' ) || 'en',
    dropdownOpen: false,
};

const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        toggleDropdown: ( state ) => {
            state.dropdownOpen = !state.dropdownOpen;
        },
        changeLanguage: ( state, action ) => {
            state.lang = action.payload;
            localStorage.setItem( 'i18nextLng', action.payload );
        },
    },
});

export const { toggleDropdown, changeLanguage } = headerSlice.actions;

export default headerSlice.reducer;
