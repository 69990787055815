// src/features/button/buttonSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  buttonClicked: false,
};

const buttonSlice = createSlice({
  name: "button",
  initialState,
  reducers: {
    setButtonState: ( state, action ) => {
      state.buttonClicked = action.payload;
    },
  },
});

export const { setButtonState } = buttonSlice.actions;
export default buttonSlice.reducer;
