// src/features/sum/sumSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
};

const ammountSlice = createSlice({
  name: "ammount",
  initialState,
  reducers: {
    setSumValue: ( state, action ) => {
      state.value = action.payload;
    },
  },
});

export const { setAmmountValue } = ammountSlice.actions;
export default ammountSlice.reducer;
